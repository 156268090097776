import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body pb-0" }
const _hoisted_2 = { class: "d-flex flex-column justify-content-between h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"pt-15 mb-10\"><h3 class=\"text-dark text-center fs-1 fw-bolder lh-lg\"> Upgrade to Pro<br> For Limitless Features </h3><div class=\"text-center text-gray-600 fs-6 fw-bold pt-4 pb-1\"> They stoping you from amazing poorly about drive.<br> Outlines keep you honest. </div><div class=\"text-center py-7\"><a href=\"#\" class=\"btn btn-primary fs-6 px-6\" data-bs-toggle=\"modal\" data-bs-target=\"#kt_modal_create_app\">Get Started</a></div></div>", 1)),
        _createElementVNode("div", {
          class: "flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom card-rounded-bottom h-200px",
          style: _normalizeStyle({
            backgroundImage: 'url(' + _ctx.getIllustrationsPath('8.png') + ')',
          })
        }, null, 4)
      ])
    ])
  ], 2))
}