import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xxl-8" }
const _hoisted_2 = { class: "col-xl-6" }
const _hoisted_3 = { class: "col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTWidget1 = _resolveComponent("KTWidget1")!
  const _component_KTWidget2 = _resolveComponent("KTWidget2")!
  const _component_KTWidget3 = _resolveComponent("KTWidget3")!
  const _component_KTWidget4 = _resolveComponent("KTWidget4")!
  const _component_KTWidget5 = _resolveComponent("KTWidget5")!
  const _component_KTWidget6 = _resolveComponent("KTWidget6")!
  const _component_KTChartWidget1 = _resolveComponent("KTChartWidget1")!
  const _component_KTListWidget5 = _resolveComponent("KTListWidget5")!
  const _component_KTEngageWidget1 = _resolveComponent("KTEngageWidget1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_KTWidget1, { "widget-classes": "mb-5 mb-xxl-8" }),
      _createVNode(_component_KTWidget2, { "widget-classes": "mb-5 mb-xxl-8" }),
      _createVNode(_component_KTWidget3, { "widget-classes": "mb-5 mb-xxl-8" }),
      _createVNode(_component_KTWidget4, { "widget-classes": "mb-5 mb-xxl-8" }),
      _createVNode(_component_KTWidget5, { "widget-classes": "mb-5 mb-xxl-8" }),
      _createVNode(_component_KTWidget6, { "widget-classes": "mb-5 mb-xxl-8" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_KTChartWidget1, { "widget-classes": "mb-5 mb-xxl-8" }),
      _createVNode(_component_KTListWidget5, { "widget-classes": "mb-5 mb-xxl-8" }),
      _createVNode(_component_KTEngageWidget1, { "widget-classes": "mb-5 mb-xxl-8" })
    ])
  ]))
}